<template>
    <div class="workshop-content">
        <v-container id="intro" class="cyan text-center white--text" fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" md="9">
                    <h1 class="mb-5"  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">Workshops</h1>
                    <div class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">
                        <p>Ich biete Ihnen Workshops und Kurse rund um Websites und Internet.
                            Egal ob Du dich selbst in die Welt der Frontend-Programmierung stürzen willst,
                              oder ob Du eine Einschulung für die gängigsten Open Source CMS (Content Management System) und Webshops benötigst,
                               ich unterstütze dich gerne.
                        </p>
                        <p>Auf dieser Seite findest Du eine Auswahl an möglichen Workshops und Kursen.
                            Ich stelle dir aber auch gerne dein individuelles Programm zusammen.
                        </p>
                        <p class="font-weight-bold">Schreib mir unter hallo@die-webmacherin.at</p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col class="text-center">
                    <v-btn
                        v-for="(workshop, i) in workshops"
                        :key="i"
                        class="ma-1"
                        color="primary" outlined small
                        @click="$vuetify.goTo('#' + workshop.anchor, scrollOptions)"
                    >
                        {{workshop.title}}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <info-card
                        v-for="(workshop ,i) in workshops"
                        :key="i"
                        :contentProp="workshop"
                        :oddEvenProp="isEven(i)"
                    ></info-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>
import InfoCard from "../components/InfoCard.vue";

export default {
    name: "Workshops",
    components: {
        InfoCard
    },
    computed: {
      scrollOptions () {
        return {
          duration: 700,
          offset: 0,
          easing: 'easeInOutCubic',
        }
      },
    },
    methods: {
        isEven(value) {
            if (value%2 == 0)
                return true;
            else
                return false;
        }
    },
    data: () => ({
        workshops:  [
            {
                title: 'HTML & CSS für Einsteiger',
                text: '\
                    <p>HTML und CSS ist die Basis jeder Website. HTML erzeugt die Seitenstruktur, während CSS dafür sorgt, daß es auch gut aussieht.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was sind HTML & CSS?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Installieren benötigter Programme</li>\
                    <li>Erstellen einer Basis-Website</li>\
                    <li>Die wichtigsten HTML-Elemente</li>\
                    <li>Grundlegende CSS-Stile</li>\
                    <li>Block- & Inline-Elemente</li>\
                    <li>Universelles Grid & Flexbox</li>\
                    <li>Browseranpassungen oder: Warum hassen Webdesigner den Internet Explorer?</li>\
                    <li>Best Practice Coding & nützliche PHP-Tricks (ohne PHP zu lernen)</li>\
                    <li>Structured Data & Accessibility</li>\
                    <li>Ausblick: Was ist eigentlich Responsive Webdesign?</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">16 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">490€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows)</span></p>\
                ',
                image: 'images/workshops/logo-html-css.png',
                anchor: 'html-css-einsteiger'
            },
            {
                title: 'Responsive Webdesign',
                text: '\
                    <p>Mobile Endgeräte werden für die Internetnutzung immer wichtiger.\
                    In vielen Bereichen überschreiten die Zugriffszahlen auf Websites mit Smartphones bei weitem die mit Desktop-Computern.\
                    Deshalb ist es unumgänglich das Webdesign für möglichst viele Bildschirmgrößen zu optimieren.\
                    Responsive Webdesign ist hier das Mittel der Wahl.</p>\
                    <p>Für diesen Kurs solltest Du bereits die Grundlagen von HTML, CSS & JS verstehen. Der Kurs ist sowohl für Webdesigner als auch für Grafiker geeignet die ins Webdesign einsteigen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was ist Responsive Webdesign?</li>\
                    <li>Responsive Webdesign vs. Native Apps</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Installieren benötigter Programme</li>\
                    <li>Erstellen einer Basis-Website</li>\
                    <li>Mobile First Designansatz</li>\
                    <li>Breakpoints & Media Queries</li>\
                    <li>Grids: Tabellen, Frames, Floating, Flexbox & Prozente</li>\
                    <li>Elemente: Flexible Breite & mobile Tabellen</li>\
                    <li>Alternativer Designansatz mit Fluid Design</li>\
                    <li>Responsive Menü</li>\
                    <li>Ausblick: Frontend & Javascript Frameworks</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">16 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">490€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS), Windows ist nur möglich, wenn die benötigten Programme selbst installiert werden</span></p>\
                ',
                image: 'images/workshops/logo-responsive.png',
                anchor: 'responsive-webdesign'
            },
            {
                title: 'Stylesheet-Sprachen (SCSS/SASS, LESS, Stylus)',
                text: '\
                    <p>Stylesheet-Sprachen (oder CSS Preprocessors) sind eine Weiterentwicklung des klassischen CSS. Sie bringen neue Funktionen mit, die das Styling einer Website um ein vielfaches vereinfachen.</p>\
                    <p>Für diesen Kurs solltest Du bereits die Grundlagen von HTML & CSS verstehen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was sind Stylesheet-Sprachen?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Installieren benötigter Programme</li>\
                    <li>Erstellen einer Basis-Website</li>\
                    <li>Variablen & Nesting</li>\
                    <li>Extends & Mixins</li>\
                    <li>Maps</li>\
                    <li>Funktionen & Operators</li>\
                    <li>Partials & Modules</li>\
                    <li>Praxisbeispiel: Frontend Frameworks</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">8 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">250€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS), Windows ist nur möglich, wenn die benötigten Programme selbst installiert werden</span></p>\
                ',
                image: 'images/workshops/logo-css-preprocessors.png',
                anchor: 'stylesheet-sprachen'
            },
            {
                title: 'Javascript & jQuery für Einsteiger',
                text: '\
                    <p>Wenn HTML & CSS für die Struktur und das Aussehen der Website zuständig sind, so sorgen Javascript und jQuery für die Interaktionsmöglichkeiten. Ob Menüs, Dialoge oder Galerien, überall wird Javascript benötigt. Und jQuery macht einem das Leben in der Javascript-Welt einfacher.</p>\
                    <p>Für diesen Kurs solltest Du bereits die Grundlagen von HTML & CSS verstehen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was sind Javascript & jQuery?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Installieren benötigter Programme</li>\
                    <li>Erstellen einer Basis-Website</li>\
                    <li>Vergleich einfacher Funktionen in JS & jQuery</li>\
                    <li>Programmieren eines Menüs</li>\
                    <li>Programmieren eines einfachen Bilderkarussells</li>\
                    <li>Verwenden existierender JS/jQuery-Plugins</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">16 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">490€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows)</span></p>\
                ',
                image: 'images/workshops/logo-js-jquery.png',
                anchor: 'js-jquery-einsteiger'
            },
            {
                title: 'Arbeiten mit Frontend-CSS-Frameworks',
                text: '\
                    <p>Üblicherweise bestehen Websites aus wiederkehrenden Elementen, zum Beispiel Menüs, Boxen, Bildern, Texten, Links, etc. Damit man diese nicht jedes Mal aufs Neue erstellen muss, bieten Frontend-CSS-Frameworks einen Katalog an nützlichen, wiederverwendbaren Elementen. So kann man schnell Prototypen bauen und sich voll auf das Design konzentrieren.</p>\
                    <p>Für diesen Kurs solltest Du bereits die Grundlagen von HTML & CSS verstehen. Auch Kenntnisse von Stylesheet-Sprachen sind hilfreich, aber nicht zwingend.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was sind Frontend-Frameworks?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Installieren benötigter Programme</li>\
                    <li>Erstellen einer Basis-Website</li>\
                    <li>Bootstrap installieren</li>\
                    <li>Das Grid-System</li>\
                    <li>Erstellen eines Hauptmenüs für Desktop & Mobile</li>\
                    <li>Erstellen eines Bilderkarussells</li>\
                    <li>Weitere Elemente & Utilities</li>\
                    <li>Individuelles Theme</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">16 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">490€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS), Windows ist nur möglich, wenn die benötigten Programme selbst installiert werden</span></p>\
                ',
                image: 'images/workshops/logo-bootstrap-materialdesign.png',
                anchor: 'frontend-frameworks'
            },
            /*{
                title: 'Einführung in Vue.js',
                text: '\
                    <p>Moderne Web-Applikationen basieren auf Javascript-Frameworks wie Angular, React & Vue. Vue.js ist eines der jüngsten JS-Frameworks mit rasantem Wachstum. Inspiriert wurde es von Angular.js, bügelt aber dessen Kinderkrankheiten aus und legt großen Wert auf Nutzerfreundlichkeit. Mit Vue.js ist ein ein leichtes reaktive, schnelle & interaktive Websites zu erstellen.</p>\
                    <p>Für diesen Kurs solltest Du bereits die Grundlagen von HTML & CSS verstehen. Auch Kenntnisse von Stylesheet-Sprachen und Frontend-Frameworks sind hilfreich, aber nicht zwingend.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was sind Javascript-Frameworks?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Installieren benötigter Programme</li>\
                    <li>Erstellen eines Vue.js & Vuetify Projektes</li>\
                    <li>Programmieren eines Menüs mit Ankern & Links</li>\
                    <li>Wiederverwendbare Komponenten</li>\
                    <li>Properties & Methods</li>\
                    <li>Anwendungsbeispiel: Filtern nach Schlagworten</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">16 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">490€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS), Windows ist nur möglich, wenn die benötigten Programme selbst installiert werden</span></p>\
                ',
                image: 'images/workshops/logo-vuejs.png',
                anchor: 'einfuehrung-vuejs'
            },*/
            /*{
                title: 'Arbeiten mit Developer Tools',
                text: '\
                    <p>Es gibt viele Werkzeuge die das Leben eines Entwicklers leichter machen. Wir sehen uns eine Auswahl an und testen sie anhand eines Beispielprojektes.</p>\
                    <p>Für diesen Kurs brauchst Du nicht zwingend Vorkenntnisse, aber es macht natürlich Sinn, wenn Dz Erfahrung mit Website-Projekten hast.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Wofür braucht man Developer Tools?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Installieren benötigter Programme</li>\
                    <li>VS Code: Flexibles Open Source IDE</li>\
                    <li>Bash: Die Kommandozeile ist dein Freund</li>\
                    <li>Git: Benutzerfreundliches Versionsmanagement</li>\
                    <li>Node.js: Benötigte Plugins gut verpackt</li>\
                    <li>Webpack: Dein persönlicher Server</li>\
                    <li>Anwendungsfälle: vue-cli & wp-cli </li>\
                    <li>Ausblick: Composer & Docker</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">8 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">250€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS), Windows ist nur möglich, wenn die benötigten Programme selbst installiert werden</span></p>\
                ',
                image: 'images/workshops/logo-devtools.png',
                anchor: 'devtools'
            },*/
            /*{
                title: 'Grundlagen der SEO (Suchmaschinenoptimierung)',
                text: '\
                    <p>Viele potentielle Kunden finden eine Website über Suchmaschinen. Damit deine eigene Website dort in den Suchergebnissen auftaucht und möglichst unter den ersten Treffern ist, müssen Ihre Website und vor allem Ihre Texte für Suchmaschinen optimiert sein.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Wofür braucht man SEO?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Schlüsselwörter sind der Schlüssel</li>\
                    <li>Optimieren von Titeln & Texten</li>\
                    <li>Gute Website-Struktur</li>\
                    <li>Externe Werkzeuge: Google Analytics Suite</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">4 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">125€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows)</span></p>\
                ',
                image: 'images/workshops/logo-google-bing-yahoo.png',
                anchor: 'grundlagen-seo'
            },*/
            /*{
                title: 'Online-Marketing & Social Media',
                text: '\
                    <p>Im World Wide Web stellt man sich der Konkurrenz aus der ganzen Welt. Um dort nicht unterzugehen und um die eigene Zielgruppe zu erreichen sind Online-Marketing und Präsenzen auf Social-Media-Kanälen das Mittel der Wahl.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Wofür brauche ich Online-Marketing?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Google Analytics Suite: Ein mächtiges Marketing-Werkzeug</li>\
                    <li>Facebook & Instagram</li>\
                    <li>Twitter & Snapchat</li>\
                    <li>Pinterest & Tumblr</li>\
                    <li>LinkedIn & Xing</li>\
                    <li>YouTube & Vimeo</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">8 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">250€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows)</span></p>\
                ',
                image: 'images/workshops/logo-social-media.png',
                anchor: 'online-marketing-social-media'
            },*/
            {
                title: 'Einschulung in Wordpress',
                text: '\
                    <p>Wordpress ist die beliebteste Software für Websites und Blogs. Die Popularität verdient es sich mit einfacher Bedienung, umfangreichen Tutorials & vielfältigen Möglichkeiten der Erweiterung. Wordpress ist ein gutes CMS für Einsteiger, bietet mit tausenden Plugins aber auch Möglichkeiten für aufwendigere Anwendungen.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was ist Wordpress?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Wordpress installieren</li>\
                    <li>Grundeinstellungen</li>\
                    <li>Seiten & Blogbeiträge</li>\
                    <li>Tags & Kategorien</li>\
                    <li>Die Media-Library</li>\
                    <li>Themes</li>\
                    <li>Plugins: Nützliche Helferlein oder komplett neue Funktionalitäten</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">4 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">135€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows), Hosting mit Wordpress Quick-Install</span></p>\
                    <p class="subtitle-2">Variante: <span class="font-weight-regular">Bei einer existierenden Wordpress-Installation ist eine Variante mit 2 Stunden Dauer und 70€ p. Person möglich.</span></p>\
                ',
                image: 'images/workshops/logo-wordpress.png',
                anchor: 'einschulung-wordpress'
            },
            {
                title: 'Einschulung in Wordpress & WooCommerce',
                text: '\
                    <p>Mit WooCommerce wird Wordpress zu einem vollwertigen Webshop und ist damit eine hervorragende Lösung kleinere eCommerce-Vorhaben. Dazu bietet Wordpress natürlich weiterhin die ursprünglichen CMS- & Blog-Funktionen.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was sind Wordpress & WooCommerce?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Wordpress & WooCommerce installieren</li>\
                    <li>Grundeinstellungen</li>\
                    <li>Theme konfigurieren</li>\
                    <li>Seiten & Blogbeiträge erstellen</li>\
                    <li>Produkte anlegen</li>\
                    <li>Tags & Kategorien</li>\
                    <li>Rechtssicherheit mit WooCommerce Germanized</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">6 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">190€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows), Hosting mit Wordpress Quick-Install</span></p>\
                    <p class="subtitle-2">Variante: <span class="font-weight-regular">Bei einer existierenden Wordpress-Installation ist eine Variante mit 4 Stunden Dauer und 135€ p. Person möglich. Ohne die Wordpress-Einschulung sind 2 Stunden und 70€ p. Person möglich.</span></p>\
                ',
                image: 'images/workshops/logo-wordpress-woocommerce.png',
                anchor: 'einschulung-wordpress-woocommerce'
            },
            /*{
                title: 'Einschulung in Magento',
                text: '\
                    <p>Magento ist eine mächtige eCommerce-Plattform mit großer Community & vielen Erweiterungsmöglichkeiten. Wenn sie sich komplett auf Ihren Webshop konzentrieren wollen und keinen unnötigen Schnickschnack brauchen, aber gleichzeitig sehr spezielle Funktionen benötigen, ist Magento genau das richtige für Sie.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was ist Magento?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Magento installieren</li>\
                    <li>Grundeinstellungen</li>\
                    <li>Theme konfigurieren</li>\
                    <li>Seiten & Blöcke erstellen</li>\
                    <li>Produkte anlegen</li>\
                    <li>Magento Marketplace</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">4 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">135€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows), Hosting mit Magento Quick-Install</span></p>\
                    <p class="subtitle-2">Variante: <span class="font-weight-regular">Bei einer existierenden Magento-Installation ist eine Variante mit 2 Stunden Dauer und 70€ p. Person möglich.</span></p>\
                ',
                image: 'images/workshops/logo-magento.png',
                anchor: 'einschulung-magento'
            },*/
            /*{
                title: 'Linux für Einsteiger',
                text: '\
                    <p>Linux steht den großen Betriebssystem Windows & MacOS in Sachen Bedienbarkeit in nichts mehr nach und bietet gerade bei Development-Aufgaben große Vorteile und Flexibilität. Aber auch für normale Anwender, die ein schlankes & individualisierbares Betriebssystem wollen, ist Linux eine gute Option. Und sie müssen dafür nichtmal Windows oder MacOS aufgeben.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Warum sollte ich Linux verwenden?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Partition für Linux erstellen</li>\
                    <li>Linux installieren (Ubuntu oder Linux Mint)</li>\
                    <li>Dual Boot einrichten</li>\
                    <li>Benutzeroberfläche mit Gnome Tweaker individualisieren</li>\
                    <li>Nützliche Programme: Firefox, Thunderbird, Libre Office, Todoist, KeePassX, CherryTree, etc.</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">6 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">190€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (Linux, MacOS, Windows), USB-Stick</span></p>\
                ',
                image: 'images/workshops/logo-linux.png',
                anchor: 'linux-einsteiger'
            },*/
            /*{
                title: 'Photoshop für Einsteiger',
                text: '\
                    <p>Photoshop von Adobe ist das beliebteste Programm für professionelle Bildbearbeitung. Ob Sie nun Produktfotos, Portraits oder Ihre Urlaubsfotos bearbeiten wollen, Photoshop bietet Ihnen die nötigen Werkzeuge, sowohl für subtile Farbkorrektur, als auch für fantastische Effekte.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was ist Photoshop?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Grundlegende Bedienung & Tastenkürzel</li>\
                    <li>Ebenen</li>\
                    <li>Zuschneiden, Skalieren & Transformieren</li>\
                    <li>Gradiationskurven & andere Einstellungsebenen</li>\
                    <li>Reparieren & Stempeln</li>\
                    <li>Abwedler, Nachbelichter & Schwamm</li>\
                    <li>Weichzeichnen</li>\
                    <li>Maskieren mit verschiedenen Methoden</li>\
                    <li>Mischmodi & Ebenendeckkraft</li>\
                    <li>Ebeneneffekte & Filter</li>\
                    <li>Praxisbeispiel: Portrait retuschieren</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">6 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">190€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (MacOS, Windows), Adobe Photoshop</span></p>\
                ',
                image: 'images/workshops/logo-photoshop.png',
                anchor: 'photoshop-einsteiger'
            },*/
            /*{
                title: 'Indesign für Einsteiger',
                text: '\
                    <p>InDesign von Adobe ist eine professionelle Lösung zum erstellen von größeren Dokumenten. Sie können Broschüren, PDFs, Präsentationen oder gar ein ganzes Buch mit InDesign genauso gestalten, wie Flyer oder Dokumentationen. Es erleichtert die Arbeit mit Vorlagen, Schriftdefinitionen, etc.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was ist InDesign?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Grundlegende Bedienung</li>\
                    <li>Seitenvorlagen & Druckbögen</li>\
                    <li>Spalten, Raster & Hilfslinien</li>\
                    <li>Absatz- & Zeichenformate</li>\
                    <li>Bilder & Texte setzen</li>\
                    <li>Automatische Nummerierung & Inhaltsverzeichnis</li>\
                    <li>PDF exportieren</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">4 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">135€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (MacOS, Windows), Adobe InDesign</span></p>\
                ',
                image: 'images/workshops/logo-indesign.png',
                anchor: 'indesign-einsteiger'
            },*/
            /*{
                title: 'Illustrator für Einsteiger',
                text: '\
                    <p>Illustrator von Adobe eignet sich besonders um Grafiken oder Logos zu entwerfen. Selbst ganze Schriftarten kann man damit designen, oder aber kleinere Drucksachen wie Visitenkarten. Mit Hilfe von Kurven entstehen unendlich skalierbare Grafiken bei kleiner Dateigröße in modernen Formaten wie SVG.</p>\
                    <p>Dieser Kurs ist für Anfänger genauso geeignet, wie für Wiedereinsteiger die Ihr Wissen auffrischen wollen.</p>\
                    <p class="subtitle-2 mb-0">Kursinhalt</p>\
                    <ul class="mb-5">\
                    <li>Was ist Illustrator?</li>\
                    <li>Wo finde ich Informationen & Tutorials?</li>\
                    <li>Grundlegende Bedienung</li>\
                    <li>Komplexe Formen mit Kurven</li>\
                    <li>Pathfinder</li>\
                    <li>Kurveneffekte</li>\
                    <li>Farben & Verläufe</li>\
                    <li>Schriftlogo entwerfen</li>\
                    <li>Beispiel: Comiczeichnen in Illustrator</li>\
                    </ul>\
                    <p class="subtitle-2 mb-0">Teilnehmer: <span class="font-weight-regular">4-10 Personen</span></p>\
                    <p class="subtitle-2 mb-0">Dauer: <span class="font-weight-regular">4 Stunden</span></p>\
                    <p class="subtitle-2">Kosten: <span class="font-weight-regular">135€ p. Person</span></p>\
                    <p class="subtitle-2">Voraussetzungen: <span class="font-weight-regular">Eigener Computer (MacOS, Windows), Adobe Illustrator</span></p>\
                ',
                image: 'images/workshops/logo-illustrator.png',
                anchor: 'illustrator-einsteiger'
            },*/
        ]
    })
};
</script>

<template>
    <v-card
        class="mb-7 pa-7"
        elevation="7"
    >
        <a :id="contentProp.anchor"></a>
        <v-row  align="center" no-gutters>
            <template v-if="$vuetify.breakpoint.smAndDown">
                <v-col cols="12" md="3" order="first">
                    <v-img
                        class="ma-5"
                        contain
                        max-height="220px"
                        :src="require('@/assets/' + contentProp.image)"
                    />
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12" md="3" :order="oddEvenProp ? 'first' : 'last'">
                    <v-img
                        class="ma-5"
                        contain
                        max-height="270px"
                        :src="require('@/assets/' + contentProp.image + '')"
                    />
                </v-col>
            </template>
            <v-col cols="12" md="9">
                <v-card-title>{{contentProp.title}}</v-card-title>
                <v-card-text v-html="contentProp.text"></v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "InfoCard",
    props: {
        contentProp: Object,
        oddEvenProp: Boolean
    },
    data: () => ({
        //
    })
};
</script>
